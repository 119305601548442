
export const firebaseConfig = {
  apiKey: "AIzaSyCDclOgGIRs5ra32jJ4mGXh520vFiTLn9o",
  authDomain: "listosoftware-826ef.firebaseapp.com",
  databaseURL: "https://listosoftware-826ef-default-rtdb.firebaseio.com",
  projectId: "listosoftware-826ef",
  storageBucket: "listosoftware-826ef.appspot.com",
  messagingSenderId: "735903886998",
  appId: "1:735903886998:web:8f7559587bf19215c21cb7",
  measurementId: "G-KWJZJY9B4J"
};

